.projet {
    background-color: #f2f2f2;
    border-radius: 14px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 18px;
    cursor: pointer;
    user-select: none;

    &:last-child {
      margin-bottom: 0px;
    }

    .projetDatas {
        pointer-events: none;
        margin-left: 20px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;

        p {
            margin: 3px 0px;
            padding: 0px;

            &.projetTeam {
                font-family: "Barlow-Bold";
            }
        } 
    }

    .projetMedia {

        min-width: 180px;
        max-width: 180px;
        border-radius: 14px;
        overflow: hidden;
        translate: 1px;

        img {
            height: 100%;
            pointer-events: none;
            
        }
    }

  }