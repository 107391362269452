//DESC: Global
@import "fonts";
//DESC: components | global
@import "./../components/inputs/customSelect";
@import "./../components/errorMessage/errorMessage";
@import "./../components/projet/projet";

body {
  margin: 0;
  font-family: "Barlow-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App, .screen-capture {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  user-select: none;

  #appUi {
    min-width: 300px;
    max-width: 300px;
    margin: 20px 40px;
    height: calc(100vh - 138px);
    color: black;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    // gap : 20px;

    #titleZone {

      min-height: 120px;

      h1 {
        width: 100%;
        margin: 0px;
        margin-bottom: 16px;
        font-weight: 100;
        font-family: "Barlow-Bold";
        font-size: 26px;
      }

      img {
        width: 100%;
      }
    }
    

    #listeProjets {
      position: relative;
      margin-top: 16px;
      overflow: scroll;
      height: 517px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
    }

    }

  }
  
  #appMedias {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 40px;
    width: calc(100vw - 400px);
    overflow: hidden;

    .compFull {
      background-color: #f2f2f2;
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      canvas {
        height: auto;
        width: 100vw;
      }

      .setFullScreenPlayer {
        position: absolute;
        right: 10px;
        bottom : 50px;
        color: white;
        padding: 8px 16px;
        font-size: 20px;
        cursor: pointer;
        width: 32px;

        img {
          width: 100%;
        }
      }

      .legend {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #ffffff86;
        color: black;
        padding: 8px 30px;
        font-size: 14px;
        font-family: "Barlow-Bold";
      }

    }

    .compZone {
      background-color: #f2f2f2;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      // margin: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 10px;
      font-family: "Barlow-Bold";
      overflow: hidden;
      position: relative;
      font-size: 18px;

      canvas {
        // position: absolute;
        height: 100%;
        width: auto;
      }

      .legend {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #ffffff86;
        color: black;
        padding: 8px 30px;
        font-size: 14px;
      }

      .setFullScreenPlayer {
        position: absolute;
        right: 0px;
        bottom: 0px;
        color: white;
        padding: 8px 16px;
        font-size: 20px;
        cursor: pointer;
        width: 32px;

        img {
          width: 100%;
        }
      }
    }

    &.one {
      // margin: 50px;
      display: flex;
      flex-direction: column;
    }
    &.two {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &.three {
      // margin: 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    &.four {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 20px;

      .compZone {
        width: auto;
      }
    }

    #zoomScreen {
      // cursor: zoom-in;
      overflow: hidden;
      background-color: white;
      border-radius: 18px;
      height: 300px;
      width: 400px;
      pointer-events: none;
      position: absolute;
    }
 

  }
  
}


#drawPalette {
  position: absolute;
  background-color: #f2f2f2;
  width: 300px;
  height: 60px;
  right: 80px;
  bottom: 0px;
  z-index: 500000;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2px 25px;
  justify-content: space-around;
  align-items: flex-start;
  font-family: "Barlow-Bold";

  h4 {
    line-height: 10px;
    font-weight: 100;
    padding: 0px;
    margin: 0px;
    color: #262626;
  }

  #controllers {

    display: flex;
    gap: 30px;
    align-items: center;

    #colors {
      display: flex;
      gap: 4px;
      
      div {
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-sizing: border-box;

        &.current {
          box-shadow: 0px 0px 5px 0px black;
        }
        
        &#black {
          background-color: black;
        }

        &#white {
          background-color: white;
        }

        &#red {
          background-color: red;
        }

        &#green {
          background-color: green;
        }

        &#blue {
          background-color: blue;
        }
      }
    }

    #sizes {
      display: flex;
      gap: 4px;
      align-items: center;
      
      div {
        cursor: pointer;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: black;

        &.current {
          box-shadow: 0px 0px 5px 0px black;
        }

        &#s2 {
          width: 6px;
          height: 6px;
        }

        &#s4 {
          width: 12px;
          height: 12px;
        }

        &#s12 {
          width: 18px;
          height: 18px;
        }
      }
    }

    #controls {
      display: flex;
      gap: 8px;
      align-items: center;

      div {
        cursor: pointer;
      }
      
    }
  }

}


#credits {
  color: #262626;
  line-height: 22px;
  padding: 4px;
  padding-left: 25px;
  height: 30px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  background-color: white;
  width: 128px;
  font-family: 'Barlow-Bold';
  font-size: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: row;
  border-top-left-radius: 14px;
  z-index: 2;

  img {
    height: 22px;
  }

  p {
      margin: 0px;
  }
}

#options {
  width: 300px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  display: flex;
  border-radius: 14px;
  min-height: 58px;
  background-color: #f2f2f2;
  justify-content: center;
  gap: 20px;
  z-index: 1;
  margin-top: 10px;

  .optionBtn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 46px;
    background-color: white;
    margin: 6px;
    transition-duration: 0.25s;

    .btnTextHover {
      color: white;
      line-height: 34px;
      padding-left: 0px;
      overflow: hidden;
      width: 0px;
      margin: 0px;
      // display: none;
      transition-duration: 0.25s;
    }

    &:hover {
      min-width: 120px;
      .btnTextHover {
        padding-left: 10px;
        width: auto;
        display: inline;
      }
    }
  }
}


// ////////////////////////////////////////////
// Override Screen Capture

.crosshairs {
  position: fixed;
  width: auto!important;
  z-index: 2147483645;
}

.overlay.true {
  border-width: 0px!important;

  &::after {
    position: absolute;
    content : "";
    left : -3px;
    top : -3px;
    bottom : -3px;
    right : -3px;
    border : 2px dashed black;
  }
}