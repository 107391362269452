#errorMessage {
    position: fixed;
    display: flex;
    top : 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.185);
    backdrop-filter: blur(5px);
  
    #messageBox {
      padding: 40px;
      border-radius: 16px;
      background-color: #ffffff;
      font-family: "Barlow-SemiBold";
    }
  }
  