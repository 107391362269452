.custom-select {

    position: relative;
    margin-bottom: 20px;


    select {
        display: none;
    }

    .select-selected {
        outline: none;
        border: none;
        font-family: "BarlowCondensed-Bold";
        background-color: #f2f2f2;
        color: #262626;
        // width: 90px;
        height: 58px;
        border-radius: 14px;
        cursor: pointer;
        line-height: 60px;
        border: none;
        text-align: center;

        &.activ {
            color: #fff;
        }


        &:after {
            position: absolute;
            content: "";
            top: 14px;
            right: 10px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #fff transparent transparent transparent;
        }
    }

    .select-items {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        border-radius: 0px 0px 14px 14px;
        overflow: hidden;
        // animation: displayList 0.25s;
    

        .opt {
            line-height: 70px;
            font-family: "BarlowCondensed-Regular";
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin: 10px;
            border-radius: 12px;
            background-color: #f2f2f2;
            padding: 6px 10px;
            animation: displayListElem 0.3s;
            animation: name duration timing-function delay iteration-count direction fill-mode;

            &:hover, &.selectedOne {
                font-family: "BarlowCondensed-Bold";
            }

            .preview {
                position: relative;
                display: flex;
                box-sizing: border-box;
                // margin: 10px;
                width: 80px;

                &.grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-rows: 1fr;
                }

                .display {
                    box-sizing: border-box;
                    position: relative;
                    margin: 2px;
                    // width: 100%;
                    // height: 100%;
                    border-radius: 4px;
                }
            }

        }

        &.select-hide {
            display: none;
        }
    }

    @keyframes displayList {
        0% {
            height: 0px;
        }

        100% {
            height: 100px;
        }
        
    }

    @keyframes displayListElem {
        0% {
            height: 0px;
        }

        100% {
            height: 70px;
        }
        
    }

}